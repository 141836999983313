import $ from 'jquery';
  
function updateCommitButton() {
    var checkboxElem_1 = $('#checkbox_confirmate_privacy_policy');
    var checkboxElem_2 = $('#checkbox_confirmate_terms_and_conditions');
    var commitButton = document.getElementsByName("commit")[0];
    if (checkboxElem_1[0].checked && checkboxElem_2[0].checked)
      commitButton.disabled = false;
    else
      commitButton.disabled = true;
}

$(document).ready(function(){
    var checkboxElem = $('#checkbox_confirmate_privacy_policy');
    if (checkboxElem[0]) {
      checkboxElem.change(function() {
        updateCommitButton();
      });
      updateCommitButton();
    }
    checkboxElem = $('#checkbox_confirmate_terms_and_conditions');
    if (checkboxElem[0]) {
      checkboxElem.change(function() {
        updateCommitButton();
      });
      updateCommitButton();
    }
});
